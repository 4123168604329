import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import * as styles from './Social.module.scss'

const SocialIconPropTypes = {
  primary: PropTypes.bool,
  hollow: PropTypes.bool,
}

const createSocialIcon =
  (IconNormal, IconHollow) =>
  ({ hollow, primary, ...props }) => {
    const socialIconProps = {
      className: cx([styles.social, primary && styles.socialPrimary]),
      ...props,
    }
    return React.createElement(hollow ? IconHollow : IconNormal, socialIconProps)
  }

export { createSocialIcon, SocialIconPropTypes }
