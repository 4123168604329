import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './Arrow.module.scss'
import cx from 'clsx'

const classNameByTurn = {
  right: styles.arrowRight,
  left: styles.arrowLeft,
}

const ArrowIcon = ({ turn, dark, primary, className, props }) => (
  <svg
    viewBox="0 0 21.82 21.82"
    className={cx([
      styles.arrow,
      !primary && dark && styles.arrowDark,
      primary && styles.arrowPrimary,
      classNameByTurn[turn],
      className,
    ])}
    {...props}
  >
    <path
      fill="currentColor"
      d="M16.8 13.25a1.11 1.11 0 0 1 1.58 0c.43.45.43 1.15 0 1.6l-6.65 6.64c-.2.2-.5.33-.81.33-.32 0-.6-.12-.81-.33l-6.65-6.65c-.45-.44-.45-1.14 0-1.59s1.14-.44 1.59 0l4.74 4.77V1.12C9.8.48 10.3 0 10.92 0c.62 0 1.1.5 1.1 1.11v16.9l4.77-4.76z"
    />
  </svg>
)

ArrowIcon.propTypes = {
  turn: PropTypes.oneOf(['right', 'down', 'left']).isRequired,
  dark: PropTypes.bool,
}

ArrowIcon.defaultProps = {
  dark: false,
}

export { ArrowIcon }
