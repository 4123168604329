import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import TransitionLink from 'gatsby-plugin-transition-link'
import { useKeyPressEvent } from 'react-use'
import { Spring } from 'react-spring/renderprops'
import cx from 'clsx'
import Facebook from '../Icons/Social/Facebook'
import Instagram from '../Icons/Social/Instagram'
import Pinterest from '../Icons/Social/Pinterest'
import NewTabLink from '../NewTabLink'
import { ParallaxParticles } from '../HeroBanner/ParallaxParticles'
import { Logo } from '../Icons/Logo'

import * as styles from './Menu.module.scss'
import { graphql, useStaticQuery } from 'gatsby'

const Menu = React.memo(({ items, darkHero, headerBackground }) => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "kontakt-page" } }) {
          frontmatter {
            facebook {
              enabled
              fullUrl
            }
            instagram {
              enabled
              fullUrl
            }
            pinterest {
              enabled
              fullUrl
            }
          }
        }
      }
    `
  )

  const [activeMenu, setActiveMenu] = useState(false)
  const delay = activeMenu ? 300 : 0
  const toggleMenu = useCallback(
    throttle(() => {
      setActiveMenu((prevActiveMenu) => {
        document.body.style.overflow = prevActiveMenu ? '' : 'hidden'
        return !prevActiveMenu
      })
    }, (items.length - 1) * 300),
    [setActiveMenu]
  )

  useEffect(() => {
    // fix toggle menu + transition between pages
    document.body.style.overflow = ''
  }, [])

  useKeyPressEvent('Escape', () => {
    if (activeMenu) {
      setActiveMenu(false)
      document.body.style.overflow = ''
    }
  })

  return (
    <div
      className={cx([styles.menu, activeMenu && styles.menuActive, darkHero && !headerBackground && styles.menuWhite])}
    >
      <div role="button" className={cx([styles.menu__hamburger])} onClick={toggleMenu}>
        <div className={styles.menu__hamburger__icon} />
      </div>
      <div className={styles.menu__content}>
        <div className={styles.menu__background}>
          <ParallaxParticles className={cx([styles.menu__background__element, styles.menu__background__element1])} />
          <ParallaxParticles className={cx([styles.menu__background__element, styles.menu__background__element2])} />
          <ParallaxParticles className={cx([styles.menu__background__element, styles.menu__background__element3])} />
          <ParallaxParticles className={cx([styles.menu__background__element, styles.menu__background__element4])} />
          <ParallaxParticles className={cx([styles.menu__background__element, styles.menu__background__element5])} />
          <ParallaxParticles className={cx([styles.menu__background__element, styles.menu__background__element6])} />
        </div>
        <div className={styles.menu__logo}>
          <Logo white />
        </div>
        <ul className={styles.menu__list}>
          {items.map(({ title, href }, i) => (
            <Spring
              key={i}
              delay={i * 100 + delay}
              to={{ opacity: activeMenu ? 1 : 0, translate: activeMenu ? 0 : 50 }}
            >
              {({ opacity, translate }) => (
                <li
                  style={{ opacity, transform: `translateX(${translate}px)` }}
                  className={styles.menu__list__item}
                  key={i}
                >
                  <TransitionLink
                    className={styles.menu__list__item__link}
                    activeClassName={styles.menu__list__item__linkActive}
                    exit={{
                      length: 0.6,
                    }}
                    entry={{ delay: 0.4, length: 0.1 }}
                    to={href}
                  >
                    {title}
                  </TransitionLink>
                </li>
              )}
            </Spring>
          ))}
        </ul>
        <div className={styles.menu__socials}>
          {frontmatter.facebook.enabled && (
            <NewTabLink
              className={cx([styles.footer__link, styles.footer__linkSocial])}
              href={frontmatter.facebook.fullUrl}
              aria-label="Facebook"
            >
              <Facebook />
            </NewTabLink>
          )}
          {frontmatter.instagram.enabled && (
            <NewTabLink
              className={cx([styles.footer__link, styles.footer__linkSocial])}
              href={frontmatter.instagram.fullUrl}
              aria-label="Instagram"
            >
              <Instagram />
            </NewTabLink>
          )}
          {frontmatter.pinterest.enabled && (
            <NewTabLink
              className={cx([styles.footer__link, styles.footer__linkSocial])}
              href={frontmatter.pinterest.fullUrl}
              aria-label="Pinterest"
            >
              <Pinterest />
            </NewTabLink>
          )}
        </div>
      </div>
    </div>
  )
})

Menu.displayName = 'Menu'

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  isMobile: PropTypes.bool,
}

export { Menu }
