import React from 'react'
import { createSocialIcon, SocialIconPropTypes } from './createSocialIcon'

const FacebookIconNormal = (props) => (
  <svg viewBox="0 0 595 595" {...props}>
    <path
      fill="currentColor"
      d="M225 115v82h-58v100h58v298h120V297h81s8-48 11-100h-91v-69c0-10 13-24 26-24h65V0h-89C222 0 225 100 225 115z"
    />
  </svg>
)

const FacebookIconHollow = (props) => (
  <svg viewBox="0 0 595.3 595.3" {...props}>
    <path
      fill="currentColor"
      d="M313.4 595.9H218c-16 0-29-13-29-29V351.5h-55.6c-16 0-29-13-29-29v-92.3c0-16 13-28.9 29-28.9H189v-46.2c0-45.9 14.4-84.9 41.6-112.8C258 14.3 296.2-.6 341.1-.6l72.9.1a29 29 0 0 1 28.9 29v85.7c0 16-13 29-29 29h-49c-15 0-18.8 3-19.6 3.8-1.3 1.6-3 5.9-3 17.8v36.5h68a29 29 0 0 1 29.2 29v92.3c0 16-13 28.9-29 28.9h-68.1v215.4c0 16-13 29-29 29zm-89.5-35h83.5v-225c0-10.7 8.7-19.4 19.3-19.4h77.7v-80.2h-77.7a19.3 19.3 0 0 1-19.3-19.3v-52.2c0-13.7 1.4-29.2 11.7-40.9 12.5-14.1 32-15.7 45.8-15.7h43V34.4l-66.8-.1c-72.3 0-117.2 46.2-117.2 120.7v62c0 10.6-8.6 19.3-19.3 19.3h-65.3v80.2h65.3c10.7 0 19.3 8.7 19.3 19.3V561zM414 34.5z"
    />
  </svg>
)

const FacebookIcon = createSocialIcon(FacebookIconNormal, FacebookIconHollow)
FacebookIcon.propTypes = SocialIconPropTypes

export default FacebookIcon
