import React from 'react'
import { FooterTop } from './FooterTop'
import { FooterBottom } from './FooterBottom'
import * as styles from './Footer.module.scss'

const FooterHr = () => <hr className={styles.footer__hr} />

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footer__inner}>
      <FooterTop />
      <FooterHr />
      <FooterBottom />
    </div>
  </footer>
)

export { Footer }
