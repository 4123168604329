import React from 'react'
import cx from 'clsx'
import * as styles from './Footer.module.scss'
import Facebook from '../Icons/Social/Facebook'
import Instagram from '../Icons/Social/Instagram'
import Pinterest from '../Icons/Social/Pinterest'
import NewTabLink from '../NewTabLink'
import { graphql, useStaticQuery } from 'gatsby'

const FooterBottom = () => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "kontakt-page" } }) {
          frontmatter {
            facebook {
              enabled
              fullUrl
            }
            instagram {
              enabled
              fullUrl
            }
            pinterest {
              enabled
              fullUrl
            }
          }
        }
      }
    `
  )

  return (
    <div className={styles.footer__bottom}>
      <div className={styles.footer__socialContainer}>
        <div className={styles.footer__socialParagraph}>Social media:</div>
        <div>
          {frontmatter.facebook.enabled && (
            <NewTabLink
              className={cx([styles.footer__link, styles.footer__linkSocial])}
              href={frontmatter.facebook.fullUrl}
              aria-label="Facebook"
            >
              <Facebook />
            </NewTabLink>
          )}
          {frontmatter.instagram.enabled && (
            <NewTabLink
              className={cx([styles.footer__link, styles.footer__linkSocial])}
              href={frontmatter.instagram.fullUrl}
              aria-label="Instagram"
            >
              <Instagram />
            </NewTabLink>
          )}
          {frontmatter.pinterest.enabled && (
            <NewTabLink
              className={cx([styles.footer__link, styles.footer__linkSocial])}
              href={frontmatter.pinterest.fullUrl}
              aria-label="Pinterest"
            >
              <Pinterest />
            </NewTabLink>
          )}
        </div>
      </div>
      <div className={styles.footer__copyright}>WSZELKIE PRAWA ZASTRZEŻONE | WU.TEKTURA</div>
    </div>
  )
}

export { FooterBottom }
