import React, { useEffect } from 'react'
import particlesSrc from '../../images/parallax-particles.svg'

const ParallaxParticles = ({ onLoad, ...props }) => {
  useEffect(() => {
    if (process.env.PRODUCTION || !onLoad) return
    onLoad()
  }, [onLoad])

  return <img {...props} onLoad={onLoad} draggable={false} src={particlesSrc} alt="particles" />
}

export { ParallaxParticles }
