import React from 'react'
import { useWindowScroll } from 'react-use'
import TransitionLink from 'gatsby-plugin-transition-link'
import cx from 'clsx'

import { withBreakpoints } from '../../hoc/withBreakpoints'
import { Menu } from '../Menu'
import { routes } from '../../URLs'
import { Logo } from '../Icons/Logo'
import * as styles from './PageNavbar.module.scss'

const menuItems = [
  { href: routes.home, title: 'Home' },
  { href: routes.oNas, title: 'O nas' },
  { href: routes.oferta, title: 'Oferta' },
  { href: routes.portfolio, title: 'Portfolio' },
  { href: routes.kontakt, title: 'Kontakt' },
]

const HeaderComponent = ({ darkHero }) => {
  const { y } = useWindowScroll()
  const activeHeader = y > 300

  return (
    <header className={cx([styles.header, activeHeader && styles.headerBackground])}>
      <div className={styles.header__logo}>
        <TransitionLink
          exit={{
            length: 0.6,
          }}
          entry={{ delay: 0.4, length: 0.1 }}
          to={routes.home}
        >
          <Logo white={!activeHeader && darkHero} />
        </TransitionLink>
      </div>
      <Menu headerBackground={activeHeader} items={menuItems} darkHero={darkHero} />
    </header>
  )
}

const PageNavbar = withBreakpoints(
  {
    mobile: {
      padding: '2.2rem 2.2rem',
      activePadding: '1.5rem 2.2rem',
      logoWidth: '5.4rem',
    },
    tablet: {
      padding: '2.6rem 2.6rem',
      activePadding: '2rem 2.6rem',
      logoWidth: '7rem',
    },
    desktop: {
      padding: '3.2rem 3.2rem',
      activePadding: '2.5rem 3.2rem',
      logoWidth: '8rem',
    },
  },
  HeaderComponent
)

export { PageNavbar }
