// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--3mBiF";
export var footer__inner = "Footer-module--footer__inner--3w6Qy";
export var footer__top = "Footer-module--footer__top--2Pmkc";
export var footer__column = "Footer-module--footer__column--1HyP9";
export var footer__columnWider = "Footer-module--footer__column--wider--1jhWr";
export var footer__columnHeader = "Footer-module--footer__column-header--1rOfT";
export var footer__columnList = "Footer-module--footer__column-list--Z8k7c";
export var footer__columnListItem = "Footer-module--footer__column-list-item--2h8nQ";
export var logo = "Footer-module--logo--3Y3gK";
export var logo__caption = "Footer-module--logo__caption--7QYdL";
export var footer__quote = "Footer-module--footer__quote--1a9TQ";
export var footer__link = "Footer-module--footer__link--3F203";
export var footer__email = "Footer-module--footer__email--2I4Lw";
export var footer__linkActive = "Footer-module--footer__link--active--3AGKC";
export var footer__hr = "Footer-module--footer__hr--2YMOa";
export var footer__bottom = "Footer-module--footer__bottom--3W3o0";
export var footer__socialContainer = "Footer-module--footer__social-container--2sCGu";
export var footer__linkSocial = "Footer-module--footer__link-social--1wi57";
export var footer__socialParagraph = "Footer-module--footer__social-paragraph--3C2oh";
export var footer__copyright = "Footer-module--footer__copyright--1tdhR";