import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import cx from 'clsx'
import * as styles from './Footer.module.scss'
import { Logo } from '../Icons/Logo'
import { graphql, useStaticQuery } from 'gatsby'

const LogoFigure = () => (
  <figure className={styles.logo}>
    <Logo white fitHeight />
    <figcaption className={styles.logo__caption}>WU.TEKTURA</figcaption>
  </figure>
)

const FooterList = ({ children }) => <ul className={styles.footer__columnList}>{children}</ul>

const FooterListItem = ({ children }) => <li className={styles.footer__columnListItem}>{children}</li>

const FooterColumn = ({ wider, children }) => (
  <div className={cx([styles.footer__column, wider && styles.footer__columnWider])}>{children}</div>
)

const FooterColumnHeader = ({ children }) => <div className={styles.footer__columnHeader}>{children}</div>

const FooterLink = (props) => (
  <TransitionLink
    exit={{
      length: 0.6,
    }}
    entry={{ delay: 0.4, length: 0.1 }}
    className={styles.footer__link}
    activeClassName={styles.footer__linkActive}
    {...props}
  />
)

const FooterTop = () => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "kontakt-page" } }) {
          frontmatter {
            email
            phoneNumbers {
              phoneNumber
            }
            companyName
            address {
              postalCode
              city
              street
              building
            }
          }
        }
      }
    `
  )

  return (
    <div className={styles.footer__top}>
      <FooterColumn wider>
        <FooterColumnHeader>
          <LogoFigure />
        </FooterColumnHeader>
        <blockquote className={styles.footer__quote}>
          Wutektura to pracownia projektowania wnętrz. Powstała po to, aby tworzyć sztukę, budzić uczucia i kreować
          funkcjonalną przestrzeń do codziennego działania.
        </blockquote>
      </FooterColumn>
      <FooterColumn>
        <FooterColumnHeader>SITEMAP</FooterColumnHeader>
        <FooterList>
          <FooterListItem>
            <FooterLink to="/">Home</FooterLink>
          </FooterListItem>
          <FooterListItem>
            <FooterLink to="/o-nas">O nas</FooterLink>
          </FooterListItem>
          <FooterListItem>
            <FooterLink to="/oferta">Oferta</FooterLink>
          </FooterListItem>
          <FooterListItem>
            <FooterLink to="/portfolio">Portfolio</FooterLink>
          </FooterListItem>
          <FooterListItem>
            <FooterLink to="/kontakt">Kontakt</FooterLink>
          </FooterListItem>
        </FooterList>
      </FooterColumn>
      <FooterColumn>
        <FooterColumnHeader>KONTAKT</FooterColumnHeader>
        <FooterList>
          <FooterListItem>{frontmatter.companyName}</FooterListItem>
          <FooterListItem>
            {frontmatter.address.street} {frontmatter.address.building}
          </FooterListItem>
          <FooterListItem>
            {frontmatter.address.postalCode} {frontmatter.address.city}
          </FooterListItem>
          <FooterListItem>
            <a className={styles.footer__email} href={`mailto:${frontmatter.email}`}>
              {frontmatter.email}
            </a>
          </FooterListItem>
        </FooterList>
      </FooterColumn>
    </div>
  )
}

export { FooterTop }
