import React from 'react'
import Media from 'react-media'

const breakpointQueries = {
  mobile: { maxWidth: 768 },
  tablet: { minWidth: 768, maxWidth: 1200 },
  desktop: { minWidth: 1200 },
}

export const withBreakpoints = (propsByBreakpoint, Component) => (props) =>
  (
    <Media queries={breakpointQueries}>
      {({ mobile, tablet, desktop }) => {
        const activeBreakpoint = (mobile && 'mobile') || (tablet && 'tablet') || (desktop && 'desktop')
        // workaround (fallback props) - react-media randomly returns false for each of breakpoints
        const propsFromBreakpoint = propsByBreakpoint[activeBreakpoint || 'fallback']
        return <Component {...props} {...propsFromBreakpoint} />
      }}
    </Media>
  )
