import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import * as styles from './Logo.module.scss'

const Logo = ({ white, fitHeight, className }) => (
  <svg
    viewBox="0 0 611.397 325.085"
    fill="currentColor"
    className={cx([styles.logo, white && styles.logoWhite, fitHeight && styles.logoFitHeight, className])}
  >
    <path
      d="M298.492 202.14c-1.125 2.688-2.09 4.947-3.022 7.223-11.511 28.086-23.012 56.174-34.552 84.248a7.504 7.504 0 01-1.744 2.521c-11.94 11.327-31.352 11.414-43.354.179-.923-.865-1.691-2.037-2.173-3.213-27.364-66.748-54.691-133.512-82.022-200.274-4.831-11.8-9.596-23.63-14.519-35.393-2.027-4.847-1.116-9.599.158-14.274 6.156-22.611 34.813-30.771 52.063-14.869a8.743 8.743 0 012.092 3.007c21.593 52.638 43.146 105.292 64.705 157.943.335.821.703 1.628 1.218 2.82.411-.889.702-1.462.947-2.057 21.572-52.645 43.156-105.285 64.665-157.957 1.193-2.919 3.352-4.709 5.74-6.356 19.678-13.554 46.452-1.775 49.711 21.918.423 3.065.177 6.229.177 9.346.008 45.137-.118 90.275.095 135.41.052 10.943 4.42 20.704 11.138 29.263 8.963 11.422 20.75 18.223 35.093 20.507 22.397 3.563 45.709-8.425 56.127-28.791 4.49-8.772 6.584-18.05 6.096-27.914-.102-2.005.117-4.026.117-6.04.008-43.606.12-87.213-.123-130.82-.034-6.026 2.057-10.626 5.738-14.844 15.59-17.866 44.909-12.813 53.626 9.223.346.876.513 1.88.515 2.826.024 49.17.268 98.343-.079 147.512-.186 26.014-9.182 49.21-25.629 69.323-17.184 21.017-39.168 34.526-65.844 39.775-33.858 6.665-64.893-.358-92.408-21.191-23.012-17.419-37.142-40.678-42.933-68.96-.648-3.168-1.033-6.386-1.619-10.091zm-133.879 71.465c-.367 15.687-12.748 29.205-28.36 30.853-10.007 1.059-18.618-1.894-25.896-8.779-.628-.597-1.142-1.4-1.471-2.204-27.35-66.738-54.674-133.485-82-200.231-4.83-11.797-9.559-23.636-14.537-35.369-1.744-4.113-1.486-8.217-.598-12.249 2.625-11.904 9.736-20.234 21.354-23.988 11.57-3.738 22.203-1.339 31.414 6.715.844.739 1.535 1.798 1.965 2.845 32.483 79.235 64.94 158.485 97.346 237.752.58 1.421.535 3.099.783 4.655zm411.471-14.685c11.169-.128 22.868 8.307 22.917 22.855.048 13.889-10.843 22.939-23.021 23.007-12.611.065-22.862-10.252-22.911-22.903-.051-12.763 10.175-22.966 23.015-22.959z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
)

Logo.propTypes = {
  fitHeight: PropTypes.bool,
  white: PropTypes.bool,
  className: PropTypes.string,
}
export { Logo }
