// extracted by mini-css-extract-plugin
export var menu = "Menu-module--menu--3wcqw";
export var menuWhite = "Menu-module--menu--white--1Wepy";
export var menu__hamburger__icon = "Menu-module--menu__hamburger__icon--3WfuG";
export var menuActive = "Menu-module--menu--active--3a15_";
export var menu__content = "Menu-module--menu__content--rKD_g";
export var menu__hamburger = "Menu-module--menu__hamburger--1yxnn";
export var menu__logo = "Menu-module--menu__logo--22JTw";
export var menu__socials = "Menu-module--menu__socials--2zgnV";
export var menu__list = "Menu-module--menu__list--2-5Ld";
export var menu__list__item = "Menu-module--menu__list__item--1kFRx";
export var slickCenter = "Menu-module--slick-center--2TnB6";
export var menu__item__link = "Menu-module--menu__item__link--3iiQI";
export var menu__list__item__link = "Menu-module--menu__list__item__link--aLeqD";
export var menu__list__item__linkActive = "Menu-module--menu__list__item__link--active--2iSUV";
export var menu__background = "Menu-module--menu__background--3D8GQ";
export var menu__background__element = "Menu-module--menu__background__element--1M63J";
export var menu__background__element1 = "Menu-module--menu__background__element1--2vT8D";
export var menu__background__element2 = "Menu-module--menu__background__element2--blO5t";
export var menu__background__element3 = "Menu-module--menu__background__element3--1ksZX";
export var menu__background__element4 = "Menu-module--menu__background__element4--76wA-";
export var menu__background__element5 = "Menu-module--menu__background__element5--30Smi";
export var menu__background__element6 = "Menu-module--menu__background__element6--3aJuu";