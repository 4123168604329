import React from 'react'
import { createSocialIcon, SocialIconPropTypes } from './createSocialIcon'

const InstagramIconNormal = (props) => (
  <svg viewBox="0 0 595.26 595.28" {...props}>
    <path
      fill="currentColor"
      d="M431 .03H164.31C73.76.03.1 73.71.1 164.27v266.68c0 90.56 73.67 164.24 164.23 164.24H431c90.58 0 164.25-73.68 164.25-164.24V164.27C595.24 73.71 521.57.03 431 .03zm111.44 430.92c0 61.44-50 111.43-111.44 111.43H164.32c-61.44 0-111.43-49.99-111.43-111.43V164.27c0-61.44 49.99-111.43 111.43-111.43H431c61.45 0 111.44 50 111.44 111.43v266.68z"
    />
    <path
      fill="currentColor"
      d="M297.66 144.26c-84.56 0-153.35 68.8-153.35 153.36 0 84.55 68.8 153.34 153.35 153.34 84.56 0 153.36-68.79 153.36-153.34 0-84.57-68.8-153.36-153.36-153.36zm0 253.9c-55.44 0-100.55-45.1-100.55-100.55 0-55.44 45.1-100.55 100.55-100.55 55.45 0 100.56 45.1 100.56 100.56 0 55.43-45.12 100.54-100.56 100.54zM457.45 99.48a38.9 38.9 0 0 0-27.35 11.34 38.92 38.92 0 0 0-11.37 27.39 39.05 39.05 0 0 0 11.37 27.39 39.04 39.04 0 0 0 27.35 11.33c10.2 0 20.17-4.16 27.4-11.33a39.02 39.02 0 0 0 11.33-27.4 38.9 38.9 0 0 0-11.34-27.38 38.9 38.9 0 0 0-27.39-11.34z"
    />
  </svg>
)

const InstagramIconHollow = (props) => (
  <svg viewBox="0 0 595 595" {...props}>
    <path
      fill="currentColor"
      d="M434 0H161C72 0 0 72 0 161v273c0 89 72 161 161 161h273c89 0 161-72 161-161V161C595 72 523 0 434 0zm126 434c0 70-56 126-126 126H161c-69 0-126-56-126-126V161C35 91 92 35 161 35h273c70 0 126 56 126 126v273z"
    />
    <path
      fill="currentColor"
      d="M298 135a163 163 0 1 0 0 326 163 163 0 0 0 0-326zm0 290a128 128 0 1 1 0-256 128 128 0 0 1 0 256zM464 77a48 48 0 1 0 0 96 48 48 0 0 0 0-96zm0 61a13 13 0 1 1 0-26 13 13 0 0 1 0 26z"
    />
  </svg>
)

const InstagramIcon = createSocialIcon(InstagramIconNormal, InstagramIconHollow)
InstagramIcon.propTypes = SocialIconPropTypes

export default InstagramIcon
