import React from 'react'
import { CloudinaryContext } from 'cloudinary-react'
import PropTypes from 'prop-types'
import 'swiper/swiper.scss'

import './layout.scss'
import './font-euclid-flex.scss'
import './font-delicate.scss'
import { Footer } from './Footer'
import { PageNavbar } from './PageNavbar'

const HeroContext = React.createContext({ darkHero: false })

const Layout = React.memo(({ children, darkHero }) => (
  <>
    <link
      href="https://fonts.googleapis.com/css2?family=El+Messiri:wght@400&family=Montserrat:wght@400;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap"
      rel="stylesheet"
    />
    <CloudinaryContext cloudName="wutektura">
      <HeroContext.Provider value={{ darkHero }}>
        <PageNavbar darkHero={darkHero} />
        <main>{children}</main>
        <Footer />
      </HeroContext.Provider>
    </CloudinaryContext>
  </>
))

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, HeroContext }
