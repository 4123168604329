import React from 'react'
import { createSocialIcon, SocialIconPropTypes } from './createSocialIcon'

const PinterestIconHollow = (props) => (
  <svg viewBox="0 0 783 783" {...props}>
    <path
      fill="currentColor"
      d="M688 195c-12-40-31-74-58-103s-58-51-94-67A305 305 0 0 0 400 0l-49 4c-46 7-86 20-122 42A287 287 0 0 0 88 250c-6 33-1 89 13 121 16 39 30 58 62 82 0 0 20 15 35 15 26-1 31-25 34-37l1-6 2-6c3-11 8-27 0-41l-10-13-4-5c-12-17-18-39-18-64 0-33 8-63 23-91 28-52 74-84 133-94 34-6 74-3 103 6 27 9 50 24 66 44 18 22 28 49 30 82 1 15 0 33-2 55-6 54-22 97-46 128-20 26-42 38-66 39h-3c-14 0-24-3-34-10s-16-15-18-27c-4-13 0-27 4-42l1-2 11-37c6-19 12-38 17-61 6-25 6-46 2-63-5-21-16-37-33-47a84 84 0 0 0-66-5c-32 11-57 40-67 80-6 20-7 41-5 63 2 17 5 32 12 50a1021 1021 0 0 1-2 7l-22 93-20 87-2 6c-7 30-14 61-16 98l-1 15c-1 29-3 59 1 87 1 5 3 18 15 22 2 2 6 4 12 4 10 0 16-7 27-19 24-28 45-63 63-107 8-20 14-42 19-62l3-13 11-42 22 15c20 11 42 17 66 19 23 1 46-2 72-9 20-6 39-15 57-26 63-41 106-112 121-200 5-26 6-48 6-71 0-27-3-51-9-73zm-31 138c-13 79-51 142-106 177-15 9-31 17-47 21-23 7-41 9-61 8-19-1-36-6-51-15-16-8-26-17-33-29a17 17 0 0 0-32 4l-7 31-11 44-4 12c-5 20-10 40-17 57-15 38-33 68-52 92-1-20 0-42 1-63l1-15c1-34 8-64 15-92l2-6 20-87 22-93 1-3c2-6 4-13 1-20-7-17-10-31-12-46-1-17 0-34 4-50 8-29 24-49 45-56a49 49 0 0 1 37 2c9 5 15 14 18 26 2 11 2 27-3 47a680 680 0 0 1-27 96l-1 2c-5 17-10 37-5 59 5 20 15 35 32 47 15 11 33 17 54 17h5c34-1 65-19 91-53 29-36 47-85 53-145 3-24 4-44 2-62-2-39-15-73-37-100-20-26-49-45-82-56-34-11-81-14-119-7a222 222 0 0 0-185 220 145 145 0 0 0 30 91l6 7-4 14-2 7-1 8-2 8-12-7c-27-20-38-34-51-67-11-26-16-76-11-101 6-29 18-66 28-84 22-40 55-73 96-97a297 297 0 0 1 155-41c53-2 96 11 121 22 32 14 59 33 82 59a220 220 0 0 1 59 152c0 21-2 41-6 65z"
    />
  </svg>
)

const PinterestIconNormal = (props) => (
  <svg viewBox="0 0 310 310" {...props}>
    <path
      fill="currentColor"
      d="M245 32c-21-21-51-32-83-32-49 0-80 20-96 37-21 21-33 49-33 76 0 35 15 61 39 71l5 1c5 0 9-3 10-9l4-13c1-7 0-10-4-15-7-8-10-18-10-30 0-38 28-78 80-78 42 0 67 23 67 61 0 24-5 46-14 62-7 12-18 25-36 25-7 0-14-3-18-8s-6-12-4-19l7-24c4-14 8-28 8-39 0-18-11-31-28-31-21 0-38 22-38 50 0 14 3 24 5 28l-22 93c-2 7-12 67 5 72 20 5 38-53 40-59l9-38c10 9 25 15 40 15 29 0 54-13 72-36 17-22 27-53 27-88 0-26-12-53-32-72z"
    />
  </svg>
)

const PinterestIcon = createSocialIcon(PinterestIconNormal, PinterestIconHollow)
PinterestIcon.propTypes = SocialIconPropTypes

export default PinterestIcon
